
import { Component, Watch, Vue } from 'vue-property-decorator'
import { delay } from '@panstar/common'
import { IOption } from '@/utils/interface'
import { validateEmails } from '@/utils/check'
import { PackageInfo, getPackageList } from '@/api/package'
import { queryJob, saveJob } from '@/api/jobs'
import { SurveyInfo, getSurveyList } from '@/api/survey'
import InputSearch from '@/components/InputSearch.vue'
import XBox from '@/components/XBox.vue'
import OfficeSelect from '@/components/OfficeSelect.vue'
import JobBaseSelect from '@/components/JobBaseSelect.vue'
import AcctSelect from '@/components/AcctSelect.vue'
import ItemSelect from '../check/checkSetting/itemSelect/index.vue'
import Tags from '../check/checkSetting/tags.vue'
import type { JobInfo } from '@/api/jobs'
import type { ElForm } from 'element-ui/types/form'
import { findOption, getJobBaseIds, getLastItem, array2Map } from '@/utils/common'
import { OptionModule } from '@/store/modules'
import { OrderSurveyInfo } from '@/api/order'

@Component({
  components: { InputSearch, XBox, OfficeSelect, JobBaseSelect, AcctSelect, ItemSelect, Tags },
  name: 'OfficeEdit',
})
export default class extends Vue {
  /** 数据 */
  form = {} as JobInfo
  /** 是否需要默认背调套餐 */
  hasPackage = false

  layout = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
    xl: 12,
  }

  /** 套餐数据 */
  packageList: IOption<PackageInfo>[] = []
  /** 调查项数据 */
  itemList: SurveyInfo[] = []
  /** 选中的调查项 */
  checkedList: OrderSurveyInfo[] = []

  rules = {
    baseIds: [{ type: 'array', required: true, message: '请选择职位类型', trigger: 'change' }],
    positionName: [{ required: true, message: '请输入职位名称', trigger: 'blur' }],
    ownerOfficeIds: [{ type: 'array', required: true, message: '请选择所属部门', trigger: 'change' }],
    packageId: [{ required: true, message: '请选择默认套餐', trigger: 'change' }],
    contactAcct: [{ required: true, message: '请输入背调负责人', trigger: 'change' }],
    ccEmails: [
      // { required: true, message: '请输入抄送邮箱', trigger: 'blur' },
      { validator: validateEmails, trigger: 'blur' },
    ],
  }

  get positionId() {
    return this.$route.params.id
  }

  /** 选中的套餐 */
  get curPackage() {
    return (this.packageList.find(i => i.value === this.form.packageId) || {})._origin || ({} as PackageInfo)
  }

  /** 额外调查项数据 */
  get extraItemList() {
    const ls = this.curPackage.surveyList || []
    return this.checkedList.filter(i => !ls.find(j => j.surveyId === i.surveyId))
  }

  /** 部门数据 */
  get officeList() {
    return OptionModule.officeList
  }

  @Watch('curPackage')
  onPackageChange() {
    // console.log('curPackage', this.curPackage)
    this.checkedList = (this.curPackage.surveyList || []).map(i => ({
      ...i,
      num: 1,
    }))
  }

  /** 获取套餐数据 */
  async getPackageList() {
    this.packageList = (await getPackageList()).map(i => {
      return {
        text: i.packageName,
        value: i.packageId,
        _origin: i,
      }
    })
  }

  /** 获取调查项数据 */
  async getSurveyList() {
    this.itemList = await getSurveyList()
  }

  /** 编辑额外调查项 */
  onExtra() {
    if (this.curPackage.packageId) {
      const ls = this.curPackage.surveyList
      ;(this.$refs.itemSelect as ItemSelect).show(ls, this.checkedList)
    } else {
      this.$message({ message: '请先选择套餐', type: 'warning' })
    }
  }

  /** 更新额外调查项 */
  onExtraUpdate(itemList: OrderSurveyInfo[]) {
    this.checkedList = itemList
  }

  /** 确认 */
  onOk() {
    ;(this.$refs.form as ElForm).validate(valid => {
      // console.log('valid', valid, this.form)
      if (valid) {
        this.form.baseId = getLastItem(this.form.baseIds)
        this.form.ownerOfficeId = getLastItem(this.form.ownerOfficeIds)
        this.form.ownerOfficeName = findOption(this.officeList, this.form.ownerOfficeId).text
        this.form.extraItemList = this.extraItemList.map(i => ({ surveyId: i.surveyId, surveyName: i.surveyName }))
        saveJob(this.form).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.$router.push({ name: 'jobs' })
        })
      } else {
        console.log('error submit!!')
      }
    })
  }

  created() {
    // console.log('gg', this.$route)
    this.getPackageList()
    this.getSurveyList()
    this.positionId &&
      queryJob(this.positionId).then(data => {
        data.baseIds = getJobBaseIds(data.baseId)
        data.ownerOfficeIds = [data.ownerOfficeId]
        this.hasPackage = !!data.packageId
        if (this.hasPackage) {
          // 等待套餐数据返回
          delay(
            () => {
              const map = array2Map<SurveyInfo>(this.itemList, 'surveyId')
              const extraItemList = (data.extraItemList || [])
                .map(i => map.get(i.surveyId))
                .filter(i => !!i) as SurveyInfo[]
              this.checkedList = [...this.curPackage.surveyList, ...extraItemList].map(i => ({
                ...i,
                num: 1,
              }))
            },
            () => !!this.curPackage.packageId && this.itemList.length > 0
          )
        }
        this.form = data
      })
  }
}
